<script setup lang="ts"></script>

<template>
  <div class="rk-updates-bar">
    <div class="container-fluid p-0">
      <div class="d-flex justify-content-between align-items-center">
        <slot ref="child"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
