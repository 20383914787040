<script setup lang="ts">
// Properties and events.
//
const props = defineProps<{
  name?: string
  styleOpts?: string
}>()
// Main variables.
//
const styleOpts = toRef(props, 'styleOpts')
</script>

<template>
  <div :class="[styleOpts]">
    <slot></slot>
  </div>
</template>

<style lang="scss"></style>
